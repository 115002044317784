import React from 'react';

import { Flex } from '../../../../../../utils/flexContainers.styled';
import { ReactComponent as StepOneImage } from '../../../../../../assets/images/step-one.svg';
import { ReactComponent as StepTwoImage } from '../../../../../../assets/images/step-two.svg';
import { ReactComponent as StepThreeImage } from '../../../../../../assets/images/step-three.svg';
import { ReactComponent as StepFourImage } from '../../../../../../assets/images/step-four.svg';

const Steps = ({ activeStep }) => {
  const StepsImages = {
    1: <StepOneImage />,
    2: <StepTwoImage />,
    3: <StepThreeImage />,
    4: <StepFourImage />,
  };

  return <Flex center>{StepsImages[activeStep]}</Flex>;
};

export default Steps;
