/**
    * @description Generic button component
    * <Button
        color="bubbleGum" // colores posibles son purple, bubbleGum y white
        maxWidth="100%" // o en px o cualquier otra medida
        outline
        transparent
        margin="10px auto 0 auto" // sigue las mismas reglas que el margin de css
        click={() => console.log('test')}
      > // Como children se puede pasar un svg inline, no es obligatorio
        <svg>
          ...
        </svg>
        inscribe tu cuenta a pat
        <br />
        y gana un cargo fijo gratis
    * </Button>
    * @author Camilo Ramirez
*/

import React from 'react';
import PropTypes from 'prop-types';
import {
  DefaultButton,
  OutlineButton,
  DisabledButton,
} from './styles/ButtonStyled';

const Button = ({
  children,
  color,
  margin,
  maxWidth,
  outline,
  transparent,
  disabled,
  click,
  iconOnly,
  height,
  width,
  dataButtonId,
}) => {
  let CompButton = null;
  if (disabled) {
    CompButton = DisabledButton;
  } else if (outline) {
    CompButton = OutlineButton;
  } else {
    CompButton = DefaultButton;
  }
  return (
    <CompButton
      color={color}
      margin={margin}
      maxWidth={maxWidth}
      transparent={transparent}
      onClick={click}
      iconOnly={iconOnly}
      height={height}
      width={width}
      data-button-id={dataButtonId}
    >
      {children}
    </CompButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['purple', 'bubbleGum', 'white']),
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
  outline: PropTypes.bool,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  click: PropTypes.func,
  iconOnly: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  dataButtonId: PropTypes.string,
};

Button.defaultProps = {
  color: 'purple',
  margin: '0 auto 0 auto',
  maxWidth: 'fit-content',
  outline: false,
  transparent: false,
  disabled: false,
  click: null,
  children: null,
  iconOnly: false,
  height: 'auto',
  width: '100%',
  dataButtonId: null,
};

export default Button;
