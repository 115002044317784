const ServiceClaimed = [
    { label: 'Fibra' }
  ];

  const ReasonClaim = [
    {
      label: 'Calidad de Servicio',
      problema: [
        { label: 'Calidad de Servicio Tecnico' },
        { label: 'Corte de Servicio' },
        { label: 'Discontinuidad de Servicio' },
        { label: 'Problema de cobertura' },
        { label: 'Problemas de velocidad de conexión' },
        { label: 'Sitios o puertos bloqueados' },
      ],
    },
    {
      label: 'Ventas/Contratos',
      problema: [
        { label: 'Atraso en entrega del servicio' },
        { label: 'Falta informacion del servicio' },
        { label: 'No reconoce servicio o producto' },
        { label: 'Promoción u oferta no cumplida' },
        { label: 'Término de contrato' },
      ],
    },
    {
      label: 'Facturación',
      problema: [
        { label: 'Baja no cursada' },
        { label: 'Cobro de equipos' },
        { label: 'Desconocimiento del plan' },
        { label: 'Descuento por corte de servicio' },
        { label: 'Despacho de Factura/Ticket' },
        { label: 'Objeto Saldo/Rebaja no cursada' },
        { label: 'Oferta retención no aplicada' },
        { label: 'Pago no ingresado/aplicado' },
        { label: 'Problema temas de cobranza' },
        { label: 'Wompay Fibra optica' }
      ],
    },
    {
      label: 'Recarga/Prepago',
      problema: [
        { label: 'Bolsa basal no aplicada' },
        { label: 'Bolsa promocional no aplicada' },
        { label: 'Recarga como pago de servicio' },
        { label: 'Recarga no aplicada' },
        { label: 'Reversa Recarga' }
      ],
    },
    {
      label: 'Servicio técnico',
      problema: [
        { label: 'Activación servicio' },
        { label: 'Configuracion equipos' },
        { label: 'Equipo con problemas' }
      ],
    },
    {
      label: 'Servicio de terceros',
      problema: [
        { label: 'Servicios de terceros' }
      ],
    },
    {
      label: 'Otros',
      problema: [
        { label: 'Demora/congestión atención' },
        { label: 'Mala atención ejecutivo' },
        { label: 'Poca preparación ejecutivo' }
      ],
    }
  ];

export { ReasonClaim, ServiceClaimed };
