export const codeTypes = [
    {
        title: 'Calidad de Servicio',
        options: {
            'Calidad de Servicio Tecnico': 'R_F_CS_CST',
            'Corte de Servicio': 'R_F_CS_',
            'Discontinuidad de Servicio': 'R_F_CS_DS',
            'Problema de cobertura': 'R_F_CS_PC',
            'Problemas de velocidad de conexión': 'R_F_CS_PVC',
            'Sitios o puertos bloqueados': 'R_F_CS_SPB'
        }
    },
    {
        title: 'Ventas/Contratos',
        options: {
            'Atraso en entrega del servicio': 'R_F_VC_AES',
            'Falta informacion del servicio': 'R_F_VC_FIS',
            'No reconoce servicio o producto': 'R_F_VC_NSP',
            'Promoción u oferta no cumplida': 'R_F_VC_POC',
            'Término de contrato': 'R_F_VC_TC'
        }
    },
    {
        title: 'Facturación',
        options: {
            'Baja no cursada': 'R_F_F_BC',
            'Cobro de equipos': 'R_F_F_CE',
            'Desconocimiento del plan': 'R_F_F_DP',
            'Descuento por corte de servicio': 'R_F_F_DCS',
            'Despacho de Factura/Ticket': 'R_F_F_DFT',
            'Objeto Saldo/Rebaja no cursada': 'R_F_F_OSRC',
            'Oferta retención no aplicada': 'R_F_F_ORA',
            'Pago no ingresado/aplicado': 'R_F_F_PIA',
            'Problema temas de cobranza': 'R_F_F_PTC',
            'Wompay Fibra optica': 'R_F_F_WFO',
        }
    },
    {
        title: 'Recarga/Prepago',
        options: {
            'Bolsa basal no aplicada': 'R_F_RP_BBA',
            'Bolsa promocional no aplicada': 'R_F_RP_BPA',
            'Recarga como pago de servicio': 'R_F_RP_RCPS',
            'Recarga no aplicada': 'R_F_RP_RA',
            'Reversa Recarga': 'R_F_RP_RR'
        }
    },
    {
        title: 'Servicio técnico',
        options: {
            'Activación servicio': 'R_F_ST_AS',
            'Configuracion equipos': 'R_F_ST_CE',
            'Equipo con problemas': 'R_F_ST_EP',
        }
    },
    {
        title: 'Servicio de terceros',
        options: {
            'Servicios de terceros': 'R_F_STS_ST'
        }
    },
    {
        title: 'Otros',
        options: {
            'Demora/congestión atención': 'R_F_O_DCA',
            'Mala atención ejecutivo': 'R_F_O_MAE',
            'Poca preparación ejecutivo': 'R_F_O_PPE'
        }
    },
];
