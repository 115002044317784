import React, { useState } from 'react';
import Select from 'react-select';
import Regions from './Regions';
import chevronDown from '../../../../../../assets/images/chevron-down.svg';
import chevronLeft from '../../../../../../assets/images/chevron-left.svg';
import chevronRight from '../../../../../../assets/images/chevron-right.svg';
import { Formik, Form, Field } from 'formik';
import { Input } from '@womcorp/wom-ui-kit';
import { Flex } from '../../../../../../utils/flexContainers.styled';
import { Iconography } from '../../../../../../iconography';
import * as yup from 'yup';
import {
  TypeResponseStyled,
  DirectionContainerClaimStyled,
  TypeResponseContainerClaimStyled,
  ContainerLabel,
  StepButtons,
  FieldTitle,
} from '../styled';

const validationSchema = yup.object({
  region: yup.string().required('La Region ingresada no es válida'),
  comuna: yup
    .string()
    .test('Country must be 2-letters (ISO codes).', (value) => {
      if (!value) {
        return true;
      }
      return value.toString().length > 1;
    })
    .required('La Comuna ingresada no es válida'),
  address: yup
    .string()
    .nullable()
    .required('La Dirección ingresada no es válida'),
  responseType: yup
    .string()
    .required('El Medio Preferente seleccionado no es válido'),
});

const AddressForm = ({ formData, setFormData, nextStep, prevStep }) => {
  const [direction, setDirection] = useState('back');
  const [radio, setRadio] = useState('');

  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        if (direction === 'back') {
          prevStep();
        } else {
          nextStep();
        }
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({
        errors,
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        handleChange,
      }) => {
        const filteredOptions = Regions.find((o) => o.label === values.region);
        return (
          <Form>
            <DirectionContainerClaimStyled>
              <div>
                <div>
                  <TypeResponseStyled>
                    <FieldTitle>Región</FieldTitle>
                    <div className="select-container">
                      <Select
                        className="select"
                        classNamePrefix="contact-form"
                        name="region"
                        onInputChange={values.region}
                        placeholder={
                          values.region !== ''
                            ? values.region
                            : 'Seleccione una región'
                        }
                        onChange={(reg) => {
                          setFieldValue('region', reg.label);
                          setFieldValue('comuna', '');
                          setFieldTouched('comuna', false);
                        }}
                        onBlur={() => setFieldTouched('region', true)}
                        options={Regions}
                        value={values.region}
                      />
                      <img src={chevronDown} alt="" />
                    </div>
                  </TypeResponseStyled>
                  <TypeResponseStyled>
                    <FieldTitle>Comuna</FieldTitle>
                    <div className="select-container">
                      <Select
                        isDisabled={values.region === ''}
                        className={
                          values.region === '' ? `select-disabled` : `select `
                        }
                        classNamePrefix="contact-form"
                        name="comuna"
                        onInputChange={values.comuna}
                        placeholder={
                          values.comuna === ''
                            ? 'Seleccione una opción'
                            : values.comuna
                        }
                        onChange={(comuna) =>
                          setFieldValue('comuna', comuna.label)
                        }
                        onBlur={() => setFieldTouched('comuna', true)}
                        options={
                          values.region !== '' && filteredOptions
                            ? filteredOptions.comunas
                            : ''
                        }
                        value={values.region}
                      />
                      <img src={chevronDown} alt="" />
                    </div>
                  </TypeResponseStyled>
                </div>
                <Input
                  label="Dirección"
                  name="address"
                  icon={<Iconography.user />}
                  exampleText="Ej: Tocornal #455"
                  error={errors.address}
                  touched={values.address !== ''}
                  value={values.address}
                  onChange={handleChange}
                />
                <TypeResponseStyled>
                  <ContainerLabel>
                    <FieldTitle>Medio preferente de respuesta</FieldTitle>
                  </ContainerLabel>
                  <TypeResponseContainerClaimStyled
                    className={radio || values.responseType ? 'active' : ''}
                  >
                    <div
                      className={`respuesta-button first ${
                        radio === 'email' || values.responseType === 'Email'
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => setRadio('email')}
                      aria-hidden
                    >
                      <label>
                        <Field
                          type="radio"
                          name="responseType"
                          label="responseType"
                          value="Email"
                        />
                        <span>E-Mail</span>
                      </label>
                    </div>
                    <DirectionContainerClaimStyled
                      className={`respuesta-button first ${
                        radio === 'carta_certificada' ||
                        values.responseType === 'Carta Certificada'
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => {
                        setRadio('carta_certificada');
                      }}
                      aria-hidden
                    >
                      <label>
                        <Field
                          type="radio"
                          name="responseType"
                          label="responseType"
                          value="Carta Certificada"
                        />
                        <span>CARTA CERTIFICADA</span>
                      </label>
                    </DirectionContainerClaimStyled>
                  </TypeResponseContainerClaimStyled>
                </TypeResponseStyled>
                <div>
                  <Flex spaceBetween>
                    <StepButtons
                      className="pasos-button volver submit"
                      type="button"
                      onClick={() => {
                        setFormData(values);
                        prevStep();
                      }}
                    >
                      <img src={chevronLeft} alt="" />
                      <span className="step-h1">Paso 1</span>
                      <span className="step-h2">Info Personal</span>
                    </StepButtons>
                    <StepButtons
                      className={`pasos-button siguiente submit ${
                        !isValid ? '' : 'active'
                      }`}
                      disabled={!isValid}
                      type="submit"
                      onClick={() => setDirection('forward')}
                    >
                      <img src={chevronRight} alt="" />
                      <span className="step-h1">Paso 3</span>
                      <span className="step-h2">Info Reclamo</span>
                    </StepButtons>
                  </Flex>
                </div>
              </div>
            </DirectionContainerClaimStyled>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
