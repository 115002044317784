import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Text } from '@womcorp/wom-ui-kit';
import InOutCardResponsive from '../../../../InOutCardResponsive/InOutCardResponsive';
import { device, minWidth, size } from '../../../../../utils/device';

export const UserCompliantContainer = styled(InOutCardResponsive)`
  color: ${(p) => p.theme.colors.primaryColor};
  padding: ${rem('24px')};
  padding-bottom: ${rem('88px')};
  @media ${device.tablet} {
    padding: 0 ${rem('16px')};
  }
`;

export const Title = styled.h4`
  display: flex;
  font-size: ${rem('28px')};
  font-weight: 500;
`;

export const GoBack = styled(Title)`
  margin: 20px 0 20px;
  font-size: 20px;
  @media ${minWidth(size.tablet)} {
    display: none;
  }
`;

export const SubTitle = styled(Text)`
  color: #7c6c8a;
`;

export const StepTitleText = styled(Text)`
  color: ${(p) => p.theme.colors.primaryColor};
  font-size: ${rem('24px')};
  margin: 0;
  font-weight: bold;
`;

export const StepWarningText = styled(Text)`
  color: ${(p) => p.theme.colors.accent};
  font-size: ${rem('12px')};
  margin: 0;
`;

export const StepTitleContainer = styled.div`
  padding: 0;
  padding-bottom: ${rem('16px')};
  border-bottom: 1px solid ${(p) => p.theme.colors.greyColor};
  margin-bottom: ${rem('40px')};
  @media ${device.tablet} {
    margin-bottom: ${rem('8px')};
    padding-bottom: ${rem('4px')};
    padding-top: ${rem('16px')};
  }
`;

export const StepFormContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${rem('400px')};
  @media ${device.tablet} {
    max-width: 97%;
  }

  ${(p) =>
    p.stepFour &&
    css`
      max-width: none;
    `}
`;

export const SubmitButtonContainer = styled.div`
  padding: ${rem('24px')};
  @media ${device.tablet} {
    padding: ${rem('16px')} 0 ${rem('24px')};
  }
`;

export const TypeResponseStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .contact-form {
    &__value-container {
      height: 60px;
      padding: 0;
    }

    &__single-value {
      color: #9a89a4;
    }

    &__option {
      padding: 15px 30px;
      background-color: #ffffff;
      border-bottom: 1px solid #e6e2e8;
      cursor: pointer;
      color: #381451;
      &:hover {
        background: whitesmoke;
      }
    }

    &__placeholder {
      color: #9a89a4;
      margin: 0;
      top: 0;
      position: relative;
      transform: translate(0);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__indicator-separator {
      height: 0;
      padding: 0;
      margin: 0;
    }

    &__control {
      background: transparent;
      border: none;
      min-height: auto;
      height: auto;
      box-shadow: 0 0 0;
      padding: 0 13px;
      cursor: pointer;
    }

    &__dropdown-indicator {
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }

    &__input {
      height: 0;
      input {
        height: 0;
      }
    }
  }

  input[type='radio'] {
    max-height: 0;
    position: absolute;
    opacity: 0;
    &::before {
      width: 100px;
      height: 100px;
      background: blue;
    }
  }
`;

export const ContainerLabel = styled.div`
  margin-top: 20px;
`;

export const DirectionContainerClaimStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  .select-container {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    img {
      position: absolute;
      right: 15px;
      top: 30px;
      pointer-events: none;
      width: 20px;
      height: 10px;
    }

    .select {
      width: 100%;
      padding: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 10px 0 rgba(215, 203, 203, 0.5);
      border-radius: 5px;
      border: 1.5px solid #e6e2e8;
      font-size: 18px;
      line-height: 21px;
      font-family: 'cerapro';
      color: #9a89a4;
      position: relative;
      height: 64px;
      cursor: pointer;
      user-select: none;
      appearance: none;

      &.deleteTypeOfProblem {
        ::before {
          content: 'Seleccione una opciรณn';
          position: absolute;
          top: 20px;
          left: 15px;
        }
        div[class$='singleValue'],
        [class$='placeholder'] {
          opacity: 0;
        }
      }

      &.delete {
        ::before {
          content: 'Seleccione comuna';
          position: absolute;
          top: 20px;
          left: 15px;
        }
        div[class$='singleValue'],
        [class$='placeholder'] {
          opacity: 0;
        }
      }

      &.inactive {
        background-color: #f1f0f3;
        box-shadow: none;
        user-select: none;
        pointer-events: none;
        cursor: unset;
      }
    }

    .select-disabled {
      width: 100%;
      padding: 0;
      background-color: #f1f0f3;
      box-shadow: 0 2px 10px 0 rgba(215, 203, 203, 0.5);
      border-radius: 5px;
      border: 1.5px solid #e6e2e8;
      font-size: 18px;
      line-height: 21px;
      font-family: 'cerapro';
      color: #9a89a4;
      position: relative;
      height: 64px;
      cursor: pointer;
      user-select: none;
      appearance: none;

      &.deleteTypeOfProblem {
        ::before {
          content: 'Seleccione una opciรณn';
          position: absolute;
          top: 20px;
          left: 15px;
        }
        div[class$='singleValue'],
        [class$='placeholder'] {
          opacity: 0;
        }
      }

      &.delete {
        ::before {
          content: 'Seleccione comuna';
          position: absolute;
          top: 20px;
          left: 15px;
        }
        div[class$='singleValue'],
        [class$='placeholder'] {
          opacity: 0;
        }
      }

      &.inactive {
        background-color: #f1f0f3;
        box-shadow: none;
        user-select: none;
        pointer-events: none;
        cursor: unset;
      }
    }
  }
`;

export const TypeResponseContainerClaimStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #e6e2e8;
  overflow: hidden;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  &.active {
    border: 2px solid #381451;
  }

  .respuesta-button {
    color: #381451;
    font-family: 'cerapro';
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s ease;
    transform: skew(-0.312rad);
    width: 100%;

    label {
      cursor: pointer;
      padding: 20px 2px;
      font-style: normal;
      user-select: none;
      width: 165px;
      font-size: 14px;
      text-align: center;
      span {
        transform: skew(-6.002rad);
        display: block;
        position: relative;
        user-select: none;
        font-size: 14px;
        text-align: center;
      }
    }
    &.first {
      border-right: 2px solid #e6e2e8;
      border-left: 9px solid transparent;
    }
    &.second {
      box-shadow: 41px 0px 0px 8px white;
      border-right: 9px solid transparent;
    }
    &.active.second {
      background: #381451;
      color: white;
      box-shadow: 41px 0px 0px 8px rgba(56, 20, 81, 1);
    }
    &.active.first {
      background: #381451;
      color: white;
      border-right: 2px solid #381451;
    }
  }
`;

export const StepButtons = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  user-select: none;
  width: auto;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &.volver {
    padding-left: 20px;
    background: transparent;
    border: none;
  }
  &.volver img {
    position: absolute;
    left: 5px;
  }
  &.volver span.step-h1 {
    font-family: 'cerapro';
    font-size: 14px;
    line-height: 14px;
    color: #381451;
  }
  &.volver span.step-h2 {
    font-family: 'cerapro';
    font-size: 14px;
    line-height: 14px;
    color: #381451;
    font-weight: 600;
    pointer-events: none;
  }
  &.siguiente {
    padding-right: 20px;
    background: #ccc4d2;

    &.active {
      background: #e92070;
      pointer-events: unset;
      cursor: pointer;
    }
    &disabled {
      background: #ccc4d2;
      transition: background 0.3s ease;
      pointer-events: none;
    }
  }
  &.siguiente img {
    position: absolute;
    right: 5px;
  }
  &.siguiente span.step-h1 {
    font-family: 'cerapro';
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }
  &.siguiente span.step-h2 {
    font-family: 'cerapro';
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    font-weight: 600;
  }
`;

export const FieldTitle = styled.div`
  margin-bottom: 20px;
`;
