import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Steps from './Steps/Steps';
import PersonalDataForm from './PersonalDataForm/PersonalDataForm';
import AddressForm from './AddressForm/AddressForm';
import ClaimForm from './ClaimForm/ClaimForm';
import UserCompliantHandler from './UserCompliantHandler';
import {
  UserCompliantContainer,
  SubTitle,
  GoBack,
  Title,
  StepTitleText,
  StepWarningText,
  StepTitleContainer,
  StepFormContainer,
} from './styled';

const UserComplaintForm = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    rut: '',
    email: '',
    phoneNumber: '',
    address: '',
    region: '',
    comuna: '',
    responseType: '',
    serviceClaimed: '',
    reasonClaim: '',
    typeOfProblem: '',
    claimAmount: '',
    claimNumberBill: '',
    descriptionOfClaim: '',
  });
  const formSteps = {
    titles: {
      1: 'Información Personal',
      2: 'Dirección',
      3: 'Información del reclamo',
      4: 'Reclamo enviado',
    },
    1() {
      return (
        <PersonalDataForm
          formData={formData}
          setFormData={setFormData}
          nextStep={() => setActiveStep(2)}
        />
      );
    },
    2() {
      return (
        <AddressForm
          formData={formData}
          setFormData={setFormData}
          nextStep={() => setActiveStep(3)}
          prevStep={() => setActiveStep(1)}
        />
      );
    },
    3() {
      return (
        <ClaimForm
          formData={formData}
          setFormData={setFormData}
          nextStep={() => setActiveStep(4)}
          prevStep={() => setActiveStep(2)}
        />
      );
    },
    4() {
      return <UserCompliantHandler formData={formData} />;
    },
  };

  return (
    <>
      <GoBack onClick={() => navigate('/home/contactanos/')}>
        {`< | Atrás`}
      </GoBack>
      <Title>Formulario de reclamos</Title>
      <SubTitle>Empresa reclamada: WOM S.A.</SubTitle>
      <Steps activeStep={activeStep} />
      <UserCompliantContainer>
        {activeStep !== 4 && (
          <StepTitleContainer>
            <StepTitleText>{formSteps.titles[activeStep]}</StepTitleText>
            <StepWarningText>
              (Todos los campos son obligatorios)
            </StepWarningText>
          </StepTitleContainer>
        )}
        {activeStep !== 4 ? (
          <StepFormContainer>{formSteps[activeStep]()}</StepFormContainer>
        ) : (
          <StepFormContainer stepFour>
            {formSteps[activeStep]()}
          </StepFormContainer>
        )}
      </UserCompliantContainer>
    </>
  );
};

export default UserComplaintForm;
