import React, { useState } from 'react';
import Select from 'react-select';
import chevronDown from '../../../../../../assets/images/chevron-down.svg';
import chevronLeft from '../../../../../../assets/images/chevron-left.svg';
import chevronRight from '../../../../../../assets/images/chevron-right.svg';
import { Input } from '@womcorp/wom-ui-kit';
import { Formik, Form, Field } from 'formik';
import { Flex } from '../../../../../../utils/flexContainers.styled';
import { ServiceClaimed, ReasonClaim } from './Questions';
import { Iconography } from '../../../../../../iconography';
import * as Yup from 'yup';
import {
  AmountSubText,
  DisclaimerFibraText,
  ContainerClaimStyled,
  FieldTitle,
  ClaimNumberBillWrapper,
} from './styled';
import {
  TypeResponseStyled,
  DirectionContainerClaimStyled,
  StepButtons,
} from '../styled';

const claimFormValidationSchema = Yup.object().shape({
  serviceClaimed: Yup.string().required('Este campo es requerido'),
  reasonClaim: Yup.string().required('Este campo es requerido'),
  typeOfProblem: Yup.string().required('Este campo es requerido'),
  claimNumberBill: Yup.string()
    .max(50, 'Este campo supera los 50 caracteres'),
  claimAmount: Yup.string().required('Este campo es requerido'),
  descriptionOfClaim: Yup.string()
    .required('Este campo es requerido')
    .max(200)
});

const ClaimForm = ({ formData, setFormData, nextStep, prevStep }) => {
  const [direction, setDirection] = useState('back');

  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);

        if (direction === 'back') {
          prevStep();
        } else {
          nextStep();
        }
      }}
      validationSchema={claimFormValidationSchema}
      validateOnMount
      validate={(values) => {
        const errors = {};

        if (
          values.reasonClaim === 'Facturación' &&
          values.claimNumberBill === ''
        ) {
          errors.claimNumberBill = 'Este campo es requerido';
        }

        if (values.claimAmount > 10000000 || values.claimAmount < 0) {
          errors.claimAmount =
            'El monto a ingresar debe ser entre 0 y 10.000.000';
        }

        return errors;
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        handleChange,
      }) => {
        const reasonClaimFilter = ReasonClaim.find(
          (o) => o.label === values.reasonClaim
        );

        return (
          <Form>
            <DirectionContainerClaimStyled>
              <div>
                <div>
                  <TypeResponseStyled>
                    <FieldTitle>Servicio reclamado</FieldTitle>
                    <div className="select-container">
                      <Select
                        className="select"
                        classNamePrefix="contact-form"
                        name="serviceClaimed"
                        onInputChange={values.serviceClaimed}
                        placeholder={
                          values.serviceClaimed !== ''
                            ? values.serviceClaimed
                            : 'Selecciona una opción'
                        }
                        onChange={(serviceClaimed) => {
                          setFieldValue('serviceClaimed', serviceClaimed.label);
                        }}
                        onBlur={() => setFieldTouched('serviceClaimed', true)}
                        options={ServiceClaimed}
                        value={values.serviceClaimed}
                      />
                      <img src={chevronDown} alt="" />
                    </div>
                  </TypeResponseStyled>
                </div>

                <div>
                  <TypeResponseStyled>
                    <FieldTitle>
                      ¿Cuál es el motivo principal de tu reclamo?
                    </FieldTitle>
                    <div className="select-container">
                      <Select
                        className="select"
                        classNamePrefix="contact-form"
                        name="region"
                        onInputChange={values.reasonClaim}
                        placeholder={
                          values.reasonClaim !== ''
                            ? values.reasonClaim
                            : 'Selecciona un motivo'
                        }
                        onChange={(reasonClaim) => {
                          setFieldValue('reasonClaim', reasonClaim.label);
                          setFieldValue('typeOfProblem', '');
                          setFieldTouched('typeOfProblem', false);
                        }}
                        onBlur={() => setFieldTouched('region', true)}
                        options={ReasonClaim}
                        value={values.reasonClaim}
                      />
                      <img src={chevronDown} alt="" />
                    </div>
                  </TypeResponseStyled>
                </div>

                <TypeResponseStyled>
                  <FieldTitle>¿Qué tipo de problema tuviste?</FieldTitle>
                  <div className="select-container">
                    <Select
                      isDisabled={values.reasonClaim === ''}
                      className={
                        values.reasonClaim === ''
                          ? `select-disabled`
                          : `select `
                      }
                      classNamePrefix="contact-form"
                      name="typeOfProblem"
                      onInputChange={values.typeOfProblem}
                      placeholder={
                        values.typeOfProblem === ''
                          ? 'Selecciona una opción'
                          : values.typeOfProblem
                      }
                      onChange={(typeOfProblem) =>
                        setFieldValue('typeOfProblem', typeOfProblem.label)
                      }
                      onBlur={() => setFieldTouched('typeOfProblem', true)}
                      options={
                        values.reasonClaim ? reasonClaimFilter.problema : ''
                      }
                      value={values.typeOfProblem}
                    />
                    <img src={chevronDown} alt="" />
                  </div>
                </TypeResponseStyled>

                {values.reasonClaim === 'Facturación' && (
                  <ClaimNumberBillWrapper>
                    <Input
                      maxLength="50"
                      label="Número de factura/boleta"
                      name="claimNumberBill"
                      icon={<Iconography.billIcon />}
                      exampleText="Ej: 9 8765 4321"
                      error={errors.claimNumberBill}
                      touched={values.claimNumberBill !== ''}
                      value={values.claimNumberBill}
                      onChange={handleChange}
                    />
                  </ClaimNumberBillWrapper>
                )}

                <Input
                  maxLength="8"
                  label="Monto reclamado"
                  name="claimAmount"
                  icon={<Iconography.amountClaim />}
                  exampleText="Ej: 9000"
                  error={errors.claimAmount}
                  touched={values.claimAmount !== ''}
                  value={values.claimAmount}
                  onChange={(event) => {
                    const re = /^(0|[1-9]\d*)$/;
                    const value = event.currentTarget.value.replace(
                      /[e\.\+\-]/gi,
                      ''
                    );

                    if (value === '' || re.test(value)) {
                      setFieldValue('claimAmount', value);
                    } else {
                      setFieldValue('claimAmount', values.claimAmount);
                    }
                  }}
                />

                <AmountSubText>
                  Te recordamos que por normativas puedes impugnar cobros, no
                  obstante,
                  <span>
                    {' '}
                    debes realizar el pago de la diferencia no impugnada.
                  </span>
                </AmountSubText>

                <DisclaimerFibraText>*Fibra WOM</DisclaimerFibraText>

                <ContainerClaimStyled>
                  <Field
                    id="input-descriptionOfClaim"
                    component="textarea"
                    placeholder="Descripción del caso"
                    className="textArea"
                    maxLength="200"
                    rows="6"
                    cols="30"
                    name="descriptionOfClaim"
                    label="Descripción del caso"
                    error={
                      touched.descriptionOfClaim && errors.descriptionOfClaim
                    }
                  />
                </ContainerClaimStyled>

                <div>
                  <Flex spaceBetween>
                    <StepButtons
                      className="pasos-button volver submit"
                      type="button"
                      onClick={() => {
                        setFormData(values);
                        prevStep();
                      }}
                    >
                      <img src={chevronLeft} alt="" />
                      <span className="step-h1">Paso 2</span>
                      <span className="step-h2">Dirección</span>
                    </StepButtons>
                    <StepButtons
                      className={`pasos-button siguiente submit ${
                        !isValid ? '' : 'active'
                      }`}
                      disabled={!isValid}
                      type="submit"
                      onClick={() => setDirection('forward')}
                    >
                      <img src={chevronRight} alt="" />
                      <span className="step-h1">Paso 4</span>
                      <span className="step-h2">Enviar Reclamo</span>
                    </StepButtons>
                  </Flex>
                </div>
              </div>
            </DirectionContainerClaimStyled>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ClaimForm;
