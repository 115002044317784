const Regions = [
  {
    label: 'Tarapacá',
    comunas: [
      { label: 'Iquique' },
      { label: 'Alto Hospicio' },
      { label: 'Pozo Almonte' },
      { label: 'Camiña' },
      { label: 'Colchane' },
      { label: 'Huara' },
      { label: 'Pica' },
    ],
  },
  {
    label: 'Antofagasta',
    comunas: [
      { label: 'Antofagasta' },
      { label: 'Mejillones' },
      { label: 'Sierra Gorda' },
      { label: 'Taltal' },
      { label: 'Calama' },
      { label: 'Ollagüe' },
      { label: 'San Pedro de Atacama' },
      { label: 'Tocopilla' },
      { label: 'María Elena' },
    ],
  },
  {
    label: 'Atacama',
    comunas: [
      { label: 'Copiapó' },
      { label: 'Caldera' },
      { label: 'Tierra Amarilla' },
      { label: 'Chañaral' },
      { label: 'Diego de Almagro' },
      { label: 'Vallenar' },
      { label: 'Alto del Carmen' },
      { label: 'Freirina' },
      { label: 'Huasco' },
    ],
  },
  {
    label: 'Coquimbo',
    comunas: [
      { label: 'La Serena' },
      { label: 'Coquimbo' },
      { label: 'Andacollo' },
      { label: 'La Higuera' },
      { label: 'Paiguano' },
      { label: 'Vicuña' },
      { label: 'Illapel' },
      { label: 'Canela' },
      { label: 'Los Vilos' },
      { label: 'Salamanca' },
      { label: 'Ovalle' },
      { label: 'Combarbalá' },
      { label: 'Monte Patria' },
      { label: 'Punitaqui' },
      { label: 'Río Hurtado' },
    ],
  },
  {
    label: 'Valparaíso',
    comunas: [
      { label: 'Valparaíso' },
      { label: 'Casablanca' },
      { label: 'Concón' },
      { label: 'Juan Fernández' },
      { label: 'Puchuncaví' },
      { label: 'Quintero' },
      { label: 'Viña del Mar' },
      { label: 'Isla de Pascua' },
      { label: 'Los Andes' },
      { label: 'Calle Larga' },
      { label: 'Rinconada' },
      { label: 'San Esteban' },
      { label: 'La Ligua' },
      { label: 'Cabildo' },
      { label: 'Papudo' },
      { label: 'Petorca' },
      { label: 'Zapallar' },
      { label: 'Quillota' },
      { label: 'Calera' },
      { label: 'Hijuelas' },
      { label: 'La Cruz' },
      { label: 'Nogales' },
      { label: 'San Antonio' },
      { label: 'Algarrobo' },
      { label: 'Cartagena' },
      { label: 'El Quisco' },
      { label: 'El Tabo' },
      { label: 'Santo Domingo' },
      { label: 'San Felipe' },
      { label: 'Catemu' },
      { label: 'Llaillay' },
      { label: 'Panquehue' },
      { label: 'Putaendo' },
      { label: 'Santa María' },
      { label: 'Quilpué' },
      { label: 'Limache' },
      { label: 'Olmué' },
      { label: 'Villa Alemana' },
    ],
  },
  {
    label: 'Región del Libertador Gral. Bernardo O’Higgins',
    comunas: [
      { label: 'Rancagua' },
      { label: 'Codegua' },
      { label: 'Coinco' },
      { label: 'Coltauco' },
      { label: 'Doñihue' },
      { label: 'Graneros' },
      { label: 'Las Cabras' },
      { label: 'Machalí' },
      { label: 'Malloa' },
      { label: 'Mostazal' },
      { label: 'Olivar' },
      { label: 'Peumo' },
      { label: 'Pichidegua' },
      { label: 'Quinta de Tilcoco' },
      { label: 'Rengo' },
      { label: 'Requínoa' },
      { label: 'San Vicente' },
      { label: 'Pichilemu' },
      { label: 'La Estrella' },
      { label: 'Litueche' },
      { label: 'Marchihue' },
      { label: 'Navidad' },
      { label: 'Paredones' },
      { label: 'San Fernando' },
      { label: 'Chépica' },
      { label: 'Chimbarongo' },
      { label: 'Lolol' },
      { label: 'Nancagua' },
      { label: 'Palmilla' },
      { label: 'Peralillo' },
      { label: 'Placilla' },
      { label: 'Pumanque' },
      { label: 'Santa Cruz' },
    ],
  },
  {
    label: 'Región del Maule',
    comunas: [
      { label: 'Talca' },
      { label: 'Constitución' },
      { label: 'Curepto' },
      { label: 'Empedrado' },
      { label: 'Maule' },
      { label: 'Pelarco' },
      { label: 'Pencahue' },
      { label: 'Río Claro' },
      { label: 'San Clemente' },
      { label: 'San Rafael' },
      { label: 'Cauquenes' },
      { label: 'Chanco' },
      { label: 'Pelluhue' },
      { label: 'Curicó' },
      { label: 'Hualañé' },
      { label: 'Licantén' },
      { label: 'Molina' },
      { label: 'Rauco' },
      { label: 'Romeral' },
      { label: 'Sagrada Familia' },
      { label: 'Teno' },
      { label: 'Vichuquén' },
      { label: 'Linares' },
      { label: 'Colbún' },
      { label: 'Longaví' },
      { label: 'Parral' },
      { label: 'Retiro' },
      { label: 'San Javier' },
      { label: 'Villa Alegre' },
      { label: 'Yerbas Buenas' },
    ],
  },
  {
    label: 'Región de Ñuble',
    comunas: [
      { label: 'Cobquecura' },
      { label: 'Coelemu' },
      { label: 'Ninhue' },
      { label: 'Portezuelo' },
      { label: 'Quirihue' },
      { label: 'Ránquil' },
      { label: 'Treguaco' },
      { label: 'Bulnes' },
      { label: 'Chillán Viejo' },
      { label: 'Chillán' },
      { label: 'El Carmen' },
      { label: 'Pemuco' },
      { label: 'Pinto' },
      { label: 'Quillón' },
      { label: 'San Ignacio' },
      { label: 'Yungay' },
      { label: 'Coihueco' },
      { label: 'Ñiquén' },
      { label: 'San Carlos' },
      { label: 'San Fabián' },
      { label: 'San Nicolás' },
    ],
  },
  {
    label: 'Región del Biobío',
    comunas: [
      { label: 'Concepción' },
      { label: 'Coronel' },
      { label: 'Chiguayante' },
      { label: 'Florida' },
      { label: 'Hualqui' },
      { label: 'Lota' },
      { label: 'Penco' },
      { label: 'San Pedro de la Paz' },
      { label: 'Santa Juana' },
      { label: 'Talcahuano' },
      { label: 'Tomé' },
      { label: 'Hualpén' },
      { label: 'Lebu' },
      { label: 'Arauco' },
      { label: 'Cañete' },
      { label: 'Contulmo' },
      { label: 'Curanilahue' },
      { label: 'Los Álamos' },
      { label: 'Tirúa' },
      { label: 'Los Ángeles' },
      { label: 'Antuco' },
      { label: 'Cabrero' },
      { label: 'Laja' },
      { label: 'Mulchén' },
      { label: 'Nacimiento' },
      { label: 'Negrete' },
      { label: 'Quilaco' },
      { label: 'Quilleco' },
      { label: 'San Rosendo' },
      { label: 'Santa Bárbara' },
      { label: 'Tucapel' },
      { label: 'Yumbel' },
      { label: 'Alto Biobío' },
    ],
  },
  {
    label: 'Región de la Araucanía',
    comunas: [
      { label: 'Temuco' },
      { label: 'Carahue' },
      { label: 'Cunco' },
      { label: 'Curarrehue' },
      { label: 'Freire' },
      { label: 'Galvarino' },
      { label: 'Gorbea' },
      { label: 'Lautaro' },
      { label: 'Loncoche' },
      { label: 'Melipeuco' },
      { label: 'Nueva Imperial' },
      { label: 'Padre las Casas' },
      { label: 'Perquenco' },
      { label: 'Pitrufquén' },
      { label: 'Pucón' },
      { label: 'Saavedra' },
      { label: 'Teodoro Schmidt' },
      { label: 'Toltén' },
      { label: 'Vilcún' },
      { label: 'Villarrica' },
      { label: 'Cholchol' },
      { label: 'Angol' },
      { label: 'Collipulli' },
      { label: 'Curacautín' },
      { label: 'Ercilla' },
      { label: 'Lonquimay' },
      { label: 'Los Sauces' },
      { label: 'Lumaco' },
      { label: 'Purén' },
      { label: 'Renaico' },
      { label: 'Traiguén' },
      { label: 'Victoria' },
    ],
  },
  {
    label: 'Región de Los Ríos',
    comunas: [
      { label: 'Valdivia' },
      { label: 'Corral' },
      { label: 'Lanco' },
      { label: 'Los Lagos' },
      { label: 'Máfil' },
      { label: 'Mariquina' },
      { label: 'Paillaco' },
      { label: 'Panguipulli' },
      { label: 'La Unión' },
      { label: 'Futrono' },
      { label: 'Lago Ranco' },
      { label: 'Río Bueno' },
    ],
  },
  {
    label: 'Región de Los Lagos',
    comunas: [
      { label: 'Puerto Montt' },
      { label: 'Calbuco' },
      { label: 'Cochamó' },
      { label: 'Fresia' },
      { label: 'Frutillar' },
      { label: 'Los Muermos' },
      { label: 'Llanquihue' },
      { label: 'Maullín' },
      { label: 'Puerto Varas' },
      { label: 'Castro' },
      { label: 'Ancud' },
      { label: 'Chonchi' },
      { label: 'Curaco de Vélez' },
      { label: 'Dalcahue' },
      { label: 'Puqueldón' },
      { label: 'Queilén' },
      { label: 'Quellón' },
      { label: 'Quemchi' },
      { label: 'Quinchao' },
      { label: 'Osorno' },
      { label: 'Puerto Octay' },
      { label: 'Purranque' },
      { label: 'Puyehue' },
      { label: 'Río Negro' },
      { label: 'San Juan de la Costa' },
      { label: 'San Pablo' },
      { label: 'Chaitén' },
      { label: 'Futaleufú' },
      { label: 'Hualaihué' },
      { label: 'Palena' },
    ],
  },
  {
    label: 'Región Aisén del Gral. Carlos Ibáñez del Campo',
    comunas: [
      { label: 'Coihaique' },
      { label: 'Lago Verde' },
      { label: 'Aisén' },
      { label: 'Cisnes' },
      { label: 'Guaitecas' },
      { label: 'Cochrane' },
      { label: 'O’Higgins' },
      { label: 'Tortel' },
      { label: 'Chile Chico' },
      { label: 'Río Ibáñez' },
    ],
  },
  {
    label: 'Región de Magallanes y de la Antártica Chilena',
    comunas: [
      { label: 'Punta Arenas' },
      { label: 'Laguna Blanca' },
      { label: 'Río Verde' },
      { label: 'San Gregorio' },
      { label: 'Cabo de Hornos (Ex Navarino)' },
      { label: 'Antártica' },
      { label: 'Porvenir' },
      { label: 'Primavera' },
      { label: 'Timaukel' },
      { label: 'Natales' },
      { label: 'Torres del Paine' },
    ],
  },
  {
    label: 'Región Metropolitana de Santiago',
    comunas: [
      { label: 'Cerrillos' },
      { label: 'Cerro Navia' },
      { label: 'Conchalí' },
      { label: 'El Bosque' },
      { label: 'Estación Central' },
      { label: 'Huechuraba' },
      { label: 'Independencia' },
      { label: 'La Cisterna' },
      { label: 'La Florida' },
      { label: 'La Granja' },
      { label: 'La Pintana' },
      { label: 'La Reina' },
      { label: 'Las Condes' },
      { label: 'Lo Barnechea' },
      { label: 'Lo Espejo' },
      { label: 'Lo Prado' },
      { label: 'Macul' },
      { label: 'Maipú' },
      { label: 'Ñuñoa' },
      { label: 'Pedro Aguirre Cerda' },
      { label: 'Peñalolén' },
      { label: 'Providencia' },
      { label: 'Pudahuel' },
      { label: 'Quilicura' },
      { label: 'Quinta Normal' },
      { label: 'Recoleta' },
      { label: 'Renca' },
      { label: 'Santiago' },
      { label: 'San Joaquín' },
      { label: 'San Miguel' },
      { label: 'San Ramón' },
      { label: 'Vitacura' },
      { label: 'Puente Alto' },
      { label: 'Pirque' },
      { label: 'San José de Maipo' },
      { label: 'Colina' },
      { label: 'Lampa' },
      { label: 'Tiltil' },
      { label: 'San Bernardo' },
      { label: 'Buin' },
      { label: 'Calera de Tango' },
      { label: 'Paine' },
      { label: 'Melipilla' },
      { label: 'Alhué' },
      { label: 'Curacaví' },
      { label: 'María Pinto' },
      { label: 'San Pedro' },
      { label: 'Talagante' },
      { label: 'El Monte' },
      { label: 'Isla de Maipo' },
      { label: 'Padre Hurtado' },
      { label: 'Peñaflor' },
    ],
  },
];

export default Regions;
