import styled from 'styled-components';

export const FieldTitle = styled.div`
  margin-bottom: 20px;
`;

export const AmountSubText = styled.div`
  color: #381451;
  font-size: 14px;
  margin: 10px 70px 30px 5px;

  span {
    font-weight: bold;
  }
`;

export const DisclaimerFibraText = styled.div`
  color: #381451;
  font-size: 14px;
  margin: 0 70px 15px 5px;
`;

export const ContainerClaimStyled = styled.div`
  min-width: 340px;

  .textArea {
    resize: none;
    width: 100%;
    border-radius: 5px;
    border: 1.5px solid #e6e2e8;
    box-shadow: 0 2px 10px 0 rgba(215, 203, 203, 0);
    outline: none;
    padding: 15px;
    color: #3e2e49;
    font-family: cerapro;
    font-size: 18px;
    margin-bottom: 40px;
    transition: all 0.3s ease;
    position: relative;
    &::placeholder {
      color: #9a89a4;
    }
    &:focus {
      box-shadow: 0 2px 10px 0 rgba(215, 203, 203, 0.5);
    }
  }

  @media (max-width: 820px) {
    min-width: auto;
  }

  .submit {
    pointer-events: all;
    user-select: all;
    cursor: pointer;
    width: 100%;
  }
  &.input-valid {
    input {
      background: rgb(250, 250, 250);
      outline: none;
      border: 1px solid #b3a6ba;
    }
    label {
      font-size: 13px;
      transform: translate(36px, -14px);
    }
    span.valid-icon {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }

  &.input-invalid {
    label {
      font-size: 13px;
      transform: translate(36px, -14px);
    }
    span.warning-icon {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
`;

export const ClaimNumberBillWrapper = styled.div`
  margin-bottom: 35px;
`;
