import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Loading from '../../../../Loading/Loading';
import ClaimSuccess from './ClaimSuccess/ClaimSuccess';
import ClaimError from './ClaimError/ClaimError';
import { codeTypes } from './codeTypes';
import { device } from '../../../../../utils/device';
import { AuthService, ClaimsService } from '../../../../../services';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const UserCompliantHandler = ({ formData }) => {
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);
  const [isError, setIsError] = useState(false);
  const [claimNumber, setClaimNumber] = useState();

  const fetchClaim = async (transformBody) => {
    setIsLoadingPlan(true);

    try {
      const [responseService] = await ClaimsService.getClaims(transformBody);
      setClaimNumber(responseService.complainId);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingPlan(false);
    }
  };

  const transformBody = () => {
    const bodyRequest = {
      firstName: '',
      lastName: '',
      documentNumber: '',
      claimNumberBill: '',
      preferrerResponseChannel: '',
      subscriberContactNumber: '',
      contactEmail: '',
      complainType3: '',
      attentionDetail: '',
      affectedService: '',
      complainedAmount: '',
      address: '',
      commune: '',
      region: '',
      typeOfDevice: 'Smartphone',
    };
    const { typeOfProblem } = formData;
    let storedKey;
    const finalKeyCode = [];

    codeTypes.map((element) => {
      storedKey = element.options[typeOfProblem];

      return finalKeyCode.push(storedKey);
    });

    const finalKeyCodeFiltered = finalKeyCode.filter((element) => {
      return element !== undefined;
    });

    bodyRequest.firstName = formData.firstName;
    bodyRequest.lastName = formData.lastName;
    bodyRequest.documentNumber = formData.rut;
    bodyRequest.claimNumberBill = formData.claimNumberBill;
    bodyRequest.preferrerResponseChannel = formData.responseType;
    bodyRequest.subscriberContactNumber = formData.phoneNumber;
    bodyRequest.contactEmail = formData.email;
    bodyRequest.complainType3 = finalKeyCodeFiltered[0];
    bodyRequest.attentionDetail = formData.descriptionOfClaim;
    bodyRequest.affectedService = 'Internet Fija';
    bodyRequest.complainedAmount = formData.claimAmount;
    bodyRequest.address = formData.address;
    bodyRequest.commune = formData.comuna;
    bodyRequest.region = formData.region;

    return bodyRequest;
  };

  useEffect(() => {
    const transformedBody = transformBody();
    fetchClaim(transformedBody);
  }, []);

  return (
    <>
      {isLoadingPlan && (
        <>
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </>
      )}
      {!isLoadingPlan && !isError && <ClaimSuccess claimNumber={claimNumber} />}
      {!isLoadingPlan && isError && <ClaimError onRetry={fetchClaim} />}
    </>
  );
};

export default UserCompliantHandler;
