import styled from 'styled-components';

export const ClaimHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px dashed #d4c6c6;
  padding-bottom: 8px;
  margin-bottom: 40px;

h1 {
  text-align: left;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: #24152f;
}

p {
  color: #e92070;
  font-size: 12px;
  line-height: 14px;
}
`;

export const ClaimNumberDetailStyled = styled.div`
  color: rgb(56, 20, 81);
  font-family: cerapro;
  font-size: 14px;

  strong {
    font-weight: bold;
  }
`;

export const ClaimNumberStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 35px auto 40px;
  max-width: 200px;
  width: 100%;
`;

export const SuccessMessageStyled = styled.p`
  text-align: center;
  color: #381451;
  font-size: 18px;
  max-width: 415px;
  margin: 0 auto;
  line-height: 22px;
  margin-bottom: 20px;
`;

export const LineIntersectionStyled = styled.div`
  width: 1px;
  height: 40px;
  border: 0.5px solid #7c6c8a;
`;
