import React from 'react';
import Button from '../../Button/Button';
import { ContactCardInfoStyled, GhostWrapper } from './styled';
import { ReactComponent as PhantomImage } from '../../../../../../assets/images/ghost.svg';

const ClaimError = ({ onRetry }) => (
  <>
    <GhostWrapper>
      <PhantomImage height="90px" />
    </GhostWrapper>
    <ContactCardInfoStyled style={{ 'maxWidth': '240px' }}>
      Algo falló, el reclamo no pudo ser guardado.
      <br />
      <br />
      Vuelve a intentarlo más tarde.
    </ContactCardInfoStyled>
    <Button color="bubbleGum" click={() => onRetry()} maxWidth="295px" height="45px">
      Reintentar
    </Button>
  </>
);

export default ClaimError;
