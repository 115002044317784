import React from 'react';
import Button from '../../Button/Button';
import { navigate } from 'gatsby';
import { ReactComponent as IconEnviado } from '../../../../../../assets/images/icon-enviado.svg';
import { SuccessMessageStyled, ClaimHeaderStyled } from './styled';

const ClaimSuccess = ({ claimNumber }) => {
  return (
    <>
      <ClaimHeaderStyled>
        <h1>Reclamo Enviado</h1>
      </ClaimHeaderStyled>
      <div>
        <IconEnviado style={{ display: 'block', margin: '0 auto 40px' }} />
        <SuccessMessageStyled>
          Dentro de los próximos 5 días hábiles daremos solución a tu problema, puedes dar seguimiento a tu reclamo desde el número que recibirás en tu casilla de correo electrónico
        </SuccessMessageStyled>
        <Button
          color="bubbleGum"
          click={() => navigate('/home')}
          maxWidth="295px"
          height="45px"
        >
          Volver al inicio
        </Button>
      </div>
    </>
  );
};


export default ClaimSuccess;
