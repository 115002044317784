import styled from 'styled-components';

const backgroundColor = {
  purple: '#2D1441',
  bubbleGum: '#E62771',
  white: '#FFF',
  disabled: '#DDD4D8',
};

const textColor = {
  purple: '#FFF',
  bubbleGum: '#FFF',
  white: '#2D1441',
  disabled: '#DDD4D8',
  outline: '#FFF',
};

const DefaultButton = styled.div`
  user-select: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-radius: 6px;
  font-family: 'cerapro';
  font-size: 12px;
  font-weight: 600 !important;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease;
  width: ${props => props.width};
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  outline: none;
  white-space: nowrap;
  border: 1px solid;
  height: ${props => props.height};
  margin: ${props => props.margin};
  max-width: ${props => props.maxWidth};
  background-color: ${props =>
    props.transparent ? 'transparent' : backgroundColor[props.color]};
  color: ${props => textColor[props.color]};
  border-color: ${props => backgroundColor[props.color]};
  svg {
    margin-right: ${props => (props.iconOnly ? '0px' : '10px')};
    path {
      fill: ${props => textColor[props.color]};
      transition: fill 0.3s;
    }
  }
  span {
    margin-top: 5px;

    @media (max-width: 375px) {
        font-size: 9px;
    }
  }
  &:hover {
    background-color: ${props =>
      props.transparent ? 'transparent' : textColor[props.color]};
    color: ${props => backgroundColor[props.color]};
    svg {
      path {
        fill: ${props => backgroundColor[props.color]};
      }
    }
  }
`;

const OutlineButton = styled(DefaultButton)`
  background-color: ${props => (props.transparent ? 'transparent' : '#FFF')};
  color: ${props => backgroundColor[props.color]};
  border-color: ${props => backgroundColor[props.color]};
  svg {
    path {
      fill: ${props => backgroundColor[props.color]};
    }
  }
  &:hover {
    background-color: ${props => backgroundColor[props.color]};
    color: ${textColor.outline};
    svg {
      path {
        fill: ${props => textColor[props.color]};
      }
    }
  }
`;

const DisabledButton = styled(DefaultButton)`
  background-color: ${backgroundColor.disabled};
  color: ${textColor.outline};
  border-color: ${backgroundColor.disabled};
  pointer-events: none;
  svg {
    path {
      fill: ${textColor.outline};
    }
  }
`;

export { DefaultButton, OutlineButton, DisabledButton };
