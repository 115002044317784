import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Input, Button } from '@womcorp/wom-ui-kit';
import { validateRut } from '../../../../../../utils/validators/rutValidator';
import { formatRut } from '../../../../../../utils/formatters';
import { Iconography } from '../../../../../../iconography';
import { SubmitButtonContainer } from '../styled';

const personalDataValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-zÀ-ú\s]+$/, 'El nombre ingresado no es válido')
    .required('El nombre ingresado no es válido')
    .min(2, 'El nombre ingresado no es válido')
    .max(30, 'El nombre ingresado no es válido'),
  lastName: Yup.string()
    .matches(/^[A-zÀ-ú\s]+$/, 'El apellido ingresado no es válido')
    .required('El apellido ingresado no es válido')
    .min(2, 'El apellido ingresado no es válido')
    .max(30, 'El apellido ingresado no es válido'),
  rut: Yup.string()
    .required('El RUT ingresado no es válido')
    .min(11, 'El RUT ingresado no es válido')
    .max(12, 'El RUT ingresado no es válido'),
  email: Yup.string()
    .email('El correo ingresado no es válido')
    .required('El correo ingresado no es válido')
    .max(50, 'El correo ingresado no es válido'),
  phoneNumber: Yup.string()
    .matches(/^56\d{9}$/, 'El número ingresado no es válido')
    .required('El número ingresado no es válido')
    .length(11, 'El número ingresado no es válido'),
});

const PersonalDataForm = ({ formData, setFormData, nextStep }) => {
  return (
    <Formik
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        nextStep();
      }}
      validationSchema={personalDataValidationSchema}
      validateOnMount
      validate={(values) => {
        return !validateRut(values.rut)
          ? { rut: 'El RUT ingresado no es válido' }
          : {};
      }}
    >
      {({
        values,
        errors,
        isValid,
        setFieldValue,
        setFieldTouched,
        handleChange,
      }) => (
        <Form>
          <Input
            label="Nombre"
            name="firstName"
            icon={<Iconography.user />}
            exampleText="Ej: Nicolás"
            error={errors.firstName}
            touched={values.firstName !== ''}
            value={values.firstName}
            onChange={handleChange}
          />
          <Input
            label="Apellido"
            name="lastName"
            icon={<Iconography.user />}
            exampleText="Ej: Henríquez"
            error={errors.lastName}
            touched={values.lastName !== ''}
            value={values.lastName}
            onChange={handleChange}
          />
          <Input
            label="RUT"
            name="rut"
            icon={<Iconography.rutDark />}
            exampleText="Ej: 12.345.678-9"
            error={errors.rut}
            touched={values.rut !== ''}
            value={values.rut}
            onChange={(event) => {
              setFieldValue('rut', formatRut(event.currentTarget.value));
            }}
            onBlur={() => setFieldTouched('rut', true)}
          />
          <Input
            label="E-mail"
            name="email"
            icon={<Iconography.email />}
            exampleText="Ej: usuario@correo.cl"
            error={errors.email}
            touched={values.email !== ''}
            value={values.email}
            onChange={handleChange}
          />
          <Input
            label="Teléfono"
            name="phoneNumber"
            icon={<Iconography.phone />}
            exampleText="Ej: 56 987654321"
            error={errors.phoneNumber}
            touched={values.phoneNumber !== ''}
            value={values.phoneNumber}
            onChange={handleChange}
          />
          <SubmitButtonContainer>
            <Button
              text="Continuar"
              type="submit"
              variant="primary"
              size="300px"
              full
              disabled={!isValid}
            />
          </SubmitButtonContainer>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalDataForm;
