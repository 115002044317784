import styled from 'styled-components';

export const ContactCardInfoStyled = styled.p`
  color: #7c6d89;
  font-size: 16px;
  line-height: 19px;
  max-width: 200px;
  text-align: center;
  min-height: 74px;
  margin: 10px auto 40px auto;
`;

export const GhostWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;
