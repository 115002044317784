import React from 'react';
import SEO from '../../../components/seo';
import UserComplaintForm from '../../../components/pages/home/Contactanos/UserComplaintForm/UserCompliantForm';
import { AccountService } from '../../../services';
import { SiteLayout } from '../../../components/Layouts';

const Reclamos = () => {
  const userName = AccountService.getUserName();

  return (
    <>
      <SEO title="Reclamos" />
      <SiteLayout user={userName} mobileWithoutName>
        <UserComplaintForm />
      </SiteLayout>
    </>
  );
};

export default Reclamos;
